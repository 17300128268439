import {
  testMyRentalsShallowMount,
  myRentalsShallowMount,
} from "@/tests/unit/setup";
import InvoicesFlap from "@/components/InvoicesFlap.vue";

const defaultProps = {
  invoices: [
    {
      uuid: "111222-azxd1-asd",
      downloadUrl: "www.test.es",
      viewUrl: "www.test.es",
      url: "www.test.es",
      expiresAt: "string",
    },
  ],
};

jest.mock("@/services/MyRentalsConfig");

const component = (props = {}) => {
  return myRentalsShallowMount(InvoicesFlap, {
    props: { ...defaultProps, ...props },
  });
};

beforeEach(() => {
  jest.restoreAllMocks();
});

describe("InvoicesFlap", () => {
  testMyRentalsShallowMount(InvoicesFlap, {
    props: defaultProps,
  });

  describe("Methods", () => {
    describe("openFile", () => {
      it("should call window.open with url passed by params", () => {
        // given
        const mockedUrl = "www.test-url.es";
        spyOn(window, "open");
        const wrapper = component();
        // when
        wrapper.vm.openFile(mockedUrl);
        // then
        expect(window.open).toHaveBeenCalledWith(mockedUrl);
      });
    });
  });
});
